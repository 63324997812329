// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentors-js": () => import("./../../../src/pages/mentors.js" /* webpackChunkName: "component---src-pages-mentors-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-writers-js": () => import("./../../../src/pages/writers.js" /* webpackChunkName: "component---src-pages-writers-js" */),
  "component---src-templates-mentor-js": () => import("./../../../src/templates/mentor.js" /* webpackChunkName: "component---src-templates-mentor-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-templates-writer-js": () => import("./../../../src/templates/writer.js" /* webpackChunkName: "component---src-templates-writer-js" */)
}

